import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  IconButton,
  Link,
  TextField,
} from "@mui/material";
import { ruRUPercent, ruRURub } from "dinno-util";
import React, { ChangeEvent } from "react";
import { useCookies } from "react-cookie";
import { useFragment, useMutation, usePreloadedQuery } from "react-relay";
import { graphql } from "relay-runtime";
import AppContextProviderQuery from "../queries/AppContextProviderQuery.graphql";
import CartFragment, {
  CartFragment$key,
} from "../queries/CartFragment.graphql";
import TopDiscountHintFragment, {
  TopDiscountHintFragment$key,
} from "../queries/TopDiscountHintFragment.graphql";
import UserMAInfoFragment, {
  UserMAInfoFragment$key,
} from "../queries/UserMAInfoFragment.graphql";
import { AddToCartSpecialControl } from "./AddToCartSpecialControl";
import { useAppContext } from "./AppContextProvider";
import { ReferralCode } from "./ReferralCode";
import { SignInButton } from "./SignInButton";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents React removal on save

graphql`
  fragment TopDiscountHintFragment on Query {
    discounts {
      i
      from
      to
      discount
    }
    me {
      maInfo {
        ...UserMAInfoFragment
      }
    }
  }
`;

const ReferralHelper = () => {
  const [referral, setReferral] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");

  const [updateReferral, updateInFlight] = useMutation(graphql`
    mutation TopDiscountHintReferralHelperMutation($input: UpdateUserInput!) {
      updateUser(input: $input) {
        user {
          ...Auth_user
          maInfo {
            ...UserMAInfoFragment
          }
        }
        discounts {
          i
          from
          to
          discount
        }
      }
    }
  `);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setReferral(newValue);
    if (
      newValue.match(
        /(REF|РЕФ)-[A-ZА-Я0-9]{4}-[A-ZА-Я0-9]{4}-[0-9]{4}-[0-9]{4}/
      )
    ) {
      updateReferral({
        variables: {
          input: {
            inviteCode: newValue,
          },
        },
        onError: (error) => {
          setError(error.message);
        },
      });
    } else if (newValue.length >= 23) {
      setError("Введите код вида: РЕФ-XXXX-XXXX-XXXX-XXXX");
    } else {
      setError("");
    }
  };

  return (
    <>
      Чтобы получать скидки - введите код полученный от пригласившего вас
      человека.
      <Box sx={{ my: 2 }}>
        <TextField
          label="Код приглашения"
          value={referral}
          onChange={handleChange}
          error={!!error}
          helperText={error}
          disabled={updateInFlight}
        />
      </Box>
      Либо положите в корзину:{" "}
      <AddToCartSpecialControl
        productId="UHJvZHVjdDo0MjY5"
        buttonText="Вступление в клуб (100 р)"
      />
    </>
  );
};

interface TopDiscountHintProps {
  fragmentRef: TopDiscountHintFragment$key;
  showAlways?: boolean;
}

export const TopDiscountHint = ({
  fragmentRef,
  showAlways,
}: TopDiscountHintProps) => {
  const data = useFragment(TopDiscountHintFragment, fragmentRef);
  const userMAInfo = useFragment<UserMAInfoFragment$key>(
    UserMAInfoFragment,
    data.me?.maInfo ?? null
  );

  const appContext = useAppContext();
  const appData = usePreloadedQuery(
    AppContextProviderQuery,
    appContext.queryRef
  );
  const cart = useFragment<CartFragment$key>(CartFragment, appData.cart);
  const maDiscount = cart?.discounts?.discounts?.find((d) => d?.name === "ma");
  const discount = maDiscount?.discount ?? 0;
  const nextDiscount = data.discounts?.find(
    (i) => (i?.discount ?? 0) > discount
  );
  const [cookies, setCookie, removeCookie] = useCookies(["closedDiscountHint"]);

  const type = !userMAInfo
    ? "noLogin"
    : !userMAInfo.isInProgram
    ? "noReferral"
    : (userMAInfo.attractedPurchasesAmount ?? 0) <= 10
    ? "noBonus"
    : (userMAInfo.PM ?? 0) - (userMAInfo.potentialPurchasesAmount ?? 0) < 0.01
    ? (cart?.fullSum ?? 0) > 0
      ? "discountLimit"
      : "noInfo"
    : "buyMore";

  const closedType = cookies.closedDiscountHint;
  if (closedType) {
    if (closedType !== type) removeCookie("closedDiscountHint");
  }

  const cartTotal = cart?.total || 0;

  return (
    <Collapse in={type !== closedType || showAlways}>
      <Alert
        severity="info"
        action={
          showAlways ? null : (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() =>
                setCookie("closedDiscountHint", type, {
                  maxAge: 60 * 60 * 24 * 2,
                })
              }
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
        sx={{ mb: 2, mt: { xs: 8, sm: 0 } }}
      >
        <AlertTitle>Скидки!</AlertTitle>

        {type == "noLogin" && (
          <>
            СКИДКИ! Чтобы получать накопительные скидки до 80% войдите в личный
            кабинет или зарегистрируйтесь.
            <br />
            <SignInButton sx={{ my: 2 }} />
          </>
        )}
        {type == "noReferral" && <ReferralHelper />}
        {type == "noBonus" && (
          <>
            СКИДКИ! Попросите своих знакомых зарегистрироваться на сайте по
            вашему промокоду{" "}
            <ReferralCode
              referralCode={userMAInfo?.referralCode ?? null}
              marginLeft={1}
            />
            , и получите скидку до 80%!
          </>
        )}
        {type == "buyMore" && (
          <>
            Текущая скидка {ruRUPercent.format(discount)}.
            {nextDiscount && (
              <>
                {" "}
                Чтобы получить скидку{" "}
                {ruRUPercent.format(nextDiscount.discount ?? 0)} увеличьте сумму
                покупки до {ruRURub.format(nextDiscount.from ?? 0)}.{" "}
                <Link href="/discounts">Подробности...</Link>
              </>
            )}
          </>
        )}
        {type == "discountLimit" && (
          <>
            Вы планируете совершить текущую покупку на сумму{" "}
            {ruRURub.format(cart?.fullSum || 0)}. Общая сумма ваших закупок за
            ТНП составит{" "}
            {ruRURub.format(
              (userMAInfo?.potentialPurchasesAmount ?? 0) + (cart?.fullSum || 0)
            )}{" "}
            руб. Это приведёт к превышению дисконтируемого лимита в{" "}
            {ruRURub.format(userMAInfo?.PM || 0)} руб. Из стоимости текущей
            покупки дисконтированию в размере {ruRUPercent.format(discount)}{" "}
            подлежит только сумма в{" "}
            {ruRURub.format(maDiscount?.discountedSum || 0)}. Итого, вы должны
            будете заплатить за покупку
            {maDiscount?.discountedSum || 0} Х (1-{discount || 0}) +{" "}
            {ruRURub.format(
              (cart?.fullSum || 0) - (maDiscount?.discountedSum || 0)
            )}{" "}
            = {ruRURub.format(cartTotal)}.
          </>
        )}
      </Alert>
    </Collapse>
  );
};
