import { type Route } from "../core";
import UdachlivayaPageQuery, {
  UdachlivayaPageQuery as UdachlivayaPageQueryType,
} from "../queries/UdachlivayaPageQuery.graphql";
import { type UdachlivayaPage } from "./UdachlivayaPage";

/**
 * Product page route.
 */
export default {
  path: "/udachlivaya",
  query: UdachlivayaPageQuery,
  component: () =>
    import(/* webpackChunkName: "UdachlivayaPage" */ "./UdachlivayaPage"),
  response: (data) => ({
    title: 'Скидка "Удачливая"',
    description: 'Скидка "Удачливая"',
    props: { data },
  }),
} as Route<UdachlivayaPage, UdachlivayaPageQueryType>;
