import { ContentCopy } from "@mui/icons-material";
import { Box, BoxProps, IconButton } from "@mui/material";
import React from "react";
import CopyToClipboard from "./CopyToClipboard";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy_ = React.StrictMode; // To prevent automatic removal

export type ReferralCodeProps = BoxProps & {
  referralCode: string | null;
};

export const ReferralCode = (props: ReferralCodeProps) => {
  const { referralCode, ...otherProps } = props;
  return (
    <>
      {referralCode && (
        <Box component="b" sx={{ whiteSpace: "nowrap" }} {...otherProps}>
          {referralCode}
          <CopyToClipboard>
            {({ copy }) => (
              <IconButton onClick={() => copy(referralCode)}>
                <ContentCopy />
              </IconButton>
            )}
          </CopyToClipboard>
        </Box>
      )}
    </>
  );
};
