// import { ArticleEntity, ComponentSharedDecoratedList } from "../graphql-types";

const news = [
  {
    id: "1",
    attributes: {
      title: "Первый творческий конкурс от MadNuts",
      publishedAt: "05.09.2023",
      description:
        "В социальной сети  ВКонтакте мы провели творческий конкурс на лучшую частушку, приуроченный к началу учебного года. Наши подписчики с энтузиазмом откликнулись!",
      slug: "pervyj-tvorcheskiy-konkurs",
      cover: {
        data: {
          attributes: {
            url: "https://s3.madnuts.ru/img/pervyj-tvorcheskiy-konkurs.png",
            hash: "",
            mime: "image/png",
            name: "pervyj-tvorcheskiy-konkurs",
            size: 42,
            width: 42,
            provider: "me",
          },
        },
      },
      seo: {
        id: "1",
        metaTitle: "Первый творческий конкурс от MadNuts",
        metaDescription:
          "В социальной сети  ВКонтакте мы провели творческий конкурс на лучшую частушку, приуроченный к началу учебного года. Наши подписчики с энтузиазмом откликнулись!",
        metaImage: {
          data: {
            attributes: {
              url: "https://s3.madnuts.ru/img/pervyj-tvorcheskiy-konkurs.png",
              hash: "",
              mime: "image/png",
              name: "pervyj-tvorcheskiy-konkurs",
              size: 42,
              width: 42,
              provider: "me",
            },
          },
        },
      },
      blocks: [
        {
          id: "1",
          __typename: "ComponentSharedRichText",
          body: `# Первый творческий конкурс от MadNuts

В честь начала учебного года магазин MadNuts провёл конкурс на лучшую
частушку в соцсети ВКонтакте. Нам очень хотелось, чтобы наши подписчики
проявили активность. Нам очень нравилась наша идея. И хотелось, чтобы
она понравилась участникам группы!

Задание было такое: придумать 1 куплет частушки --- четверостишие. Оно
должно было одновременно быть:

-   Весёлым и позитивным

-   Отражать тему нашей продукции и ее пользы

-   Быть связанным с темой школы, учёбы, знаний.

Призы, конечно, были объявлены заранее: это были наши прекрасные наборы
в деревянных боксах, вкусные чаи и орешки в шоколаде.

Мы знали, что наши подписчики творческие и креативные. Но чтобы
НАСТОЛЬКО!! Мы даже и не представляли. Многие участники сочинили 10-15
куплетов! Поэтому выбирать победителей и было так сложно. Но в
результате мозгового штурма нашей командой мы выбрали не трех, а четырёх
победителей: 3-е место поделили между собой 2 участника.

На фото вы видите нашу победительницу
Анастасию, получившую главный приз за частушку:
![Фото победительницы](https://s3.madnuts.ru/img/pervyj-tvorcheskiy-konkurs.png)

    В школе нет приятных трат,
    С каждого поборы.
    Закажи себе MadNuts -
    Не сдавай на шторы!

Кстати, Анастасия тоже прислала нам около 10 вариантов четверостиший.
Поздравляем победительницу и всех остальных призёров!

В результате этого конкурса мы решили:

-   Увековечить все частушки всех участников конкурса, издав их
    отдельной «книжечкой» в нашем сообществе;

-   Регулярно проводить творческие конкурсы, потому что мы видим, как
    это здорово, увлекательно и интересно!

Кто из вас еще не в нашей группе в ВК? Обязательно подписывайте и
принимайте участие в наших конкурсах! [Наша группа
ВК](https://vk.com/mad___nuts)`,
        },
      ],
    },
  },
];

const decList1 = {
  id: "1",
  __typename: "ComponentSharedDecoratedList",
  items: [
    {
      __typename: "ComponentSharedDecoratedListItem",
      id: "1",
      image: {
        id: "1",
        __typename: "ComponentSharedMedia",
        file: {
          data: {
            attributes: {
              url: "https://s3.madnuts.ru/img/icons2/2.png",
              hash: "",
              mime: "image/png",
              name: "icons2_2",
              size: 42,
              width: 42,
              provider: "me",
              alternativeText: "Сердце выложенное из орехов",
            },
          },
        },
      },
      primaryText: {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `Интернет-магазин скидок MadNuts делает орехи, сухофрукты, цукаты,специи и другие полезные и нужные 
          продукты питания в СПБ и другихрегионах более доступными и качественными для своих клиентов. **Мы любим свое дело и уверены, что 
          приносим пользу, витамины издоровье все большему количеству людей!**`,
      },
    },
    {
      __typename: "ComponentSharedDecoratedListItem",
      id: "2",
      image: {
        id: "2",
        __typename: "ComponentSharedMedia",
        file: {
          data: {
            attributes: {
              url: "https://s3.madnuts.ru/img/icons2/1.png",
              hash: "",
              mime: "image/png",
              name: "icons2_1",
              size: 42,
              width: 42,
              provider: "me",
              alternativeText: "Знак процента на жетоне",
            },
          },
        },
      },
      primaryText: {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `Мы — единственный интернет магазин орехов и сухофруктов,использующий большой набор разнообразных 
          скидок. Это значит, что **мы можем сделать для вас всю нашу продукцию значительнодешевле и доступнее, чем у конкурентов.**`,
      },
    },
    {
      __typename: "ComponentSharedDecoratedListItem",
      id: "2",
      image: {
        id: "2",
        __typename: "ComponentSharedMedia",
        file: {
          data: {
            attributes: {
              url: "https://s3.madnuts.ru/img/icons2/3.png",
              hash: "",
              mime: "image/png",
              name: "icons2_3",
              size: 42,
              width: 42,
              provider: "me",
              alternativeText: "Пачки банкнот",
            },
          },
        },
      },
      primaryText: {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `Наш бизнес может легко стать **источником дополнительного заработка** для наших покупателей 
          при минимальных трудозатратах с их стороны.`,
      },
    },
    {
      __typename: "ComponentSharedDecoratedListItem",
      id: "2",
      image: {
        id: "2",
        __typename: "ComponentSharedMedia",
        file: {
          data: {
            attributes: {
              url: "https://s3.madnuts.ru/img/icons2/4.png",
              hash: "",
              mime: "image/png",
              name: "icons2_4",
              size: 42,
              width: 42,
              provider: "me",
              alternativeText: "Яблоки на ладони",
            },
          },
        },
      },
      primaryText: {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `Мы **тщательно выбираем поставщиков-производителей**, чтобыобеспечить вас качественной продукцией
          быстро и с самымивыгодными ценами. `,
      },
    },
    {
      __typename: "ComponentSharedDecoratedListItem",
      id: "2",
      image: {
        id: "2",
        __typename: "ComponentSharedMedia",
        file: {
          data: {
            attributes: {
              url: "https://s3.madnuts.ru/img/icons2/5.png",
              hash: "",
              mime: "image/png",
              name: "2",
              size: 42,
              width: 42,
              provider: "me",
              alternativeText: "Награда за качество",
            },
          },
        },
      },
      primaryText: {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: "**Качество продукции мы гарантируем и в случае брака предоставимбесплатную замену.**",
      },
    },
  ],
};

const pochemuMadnuts = {
  id: "2",
  attributes: {
    title: "Преимущества MadNuts",
    slug: "preimushchestva",
    description: "В статье опсаны преимущества нашего магазина",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: '# <span style="color:#7fad26">Почему стоит выбирать MadNuts?</span>',
      },
      decList1,
    ],
  },
};

const mission = {
  id: "3",
  attributes: {
    title: "Миссия MadNuts",
    slug: "missiya",
    description: "Миссия нашего магазина",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
<center><img src="https://s3.madnuts.ru/img/icons2/6.png" alt="Миссия MadNuts"/></center>

# Наша миссия:
# <font size=6><span style="color:#7fad26">Мы делаем вкусное и полезное доступным!</span></font>
        
> По статистике, орехи употребляют в пищу только 40% россиян, а ежедневно это делают всего 5-6%. Это при том, что о пользе орехов знают практически все!
        
Причина очевидна: орехи и сухофрукты в России достаточно дороги для очень большой части населения. А полезность этих продуктов при планировании семейного бюджета отходит на второй план.
        
> **Можно ли сделать так, чтобы орехи и сухофрукты были доступны большему количеству людей?**
        
**<center><span style="color:#7fad26">Можно! Как? Мы знаем ответ!</span></center>**
`,
      },
      {
        id: "2",
        __typename: "ComponentSharedRichText",
        body: `
<center><img src="https://s3.madnuts.ru/img/icons2/8.png" alt="Миссия MadNuts"/></center>

**Путь один – необходимо снизить себестоимость реализуемой продукции, сохраняя при этом ее качество.**
        
Обычно для этого используют "экономию на масштабе" - снижают цену на продукт, надеясь, что объемы продаж при этом возрастут и возникнет экономия на логистике. Но этого явно не достаточно. **Необходимо более кардинальное снижение себестоимости, и оно возможно!**

> **В стоимость орехов и сухофруктов до половины расходов закладывается на рекламу!**

Получается, что наш покупатель должен оплачивать не только сам продукт, но и расходы на его рекламирование и продвижение. Но мы выбрали другой путь.

> С помощью скидочной системы клуба любителей орехов и сухофруктов **"По карману" мы исключаем расходы на рекламу, и поручаем распространение информации о нашем магазине Покупателям!**

**<center><span style="color:#7fad26">Как это работает<span></center>**
`,
      },
      {
        id: "3",
        __typename: "ComponentSharedRichText",
        body: `
Ядром нашей скидочной системы являются **реферальные скидки**. Они стимулируют наших покупателей передавать информацию о нас своим друзьям и знакомым. **Разработанная нами схема лишена традиционных недостатков, присущих MLM (Мульти Левел Маркетингу):**

> - Мы можем реализовывать самый обычный, не уникальный товар,
> - Поскольку наша продукция хорошо известна, никакого обучения ее свойствам и приемам продвижения не требуется,
> - Мы полностью сняли эффект “заработка на друзьях”, традиционно присущий MLM.

**<span style="color:#7fad26">Так мы исключаем расходы на рекламу, поручая распространение информации о нашем магазине Покупателям. Получаемую при этом экономию себестоимости мы перераспределяем между Покупателями в виде разнообразных скидок, снижая стоимость покупок в несколько раз!</span>**

<center><img src="https://s3.madnuts.ru/img/icons2/9.png" alt="Миссия MadNuts"/></center>
`,
      },
    ],
  },
};

const deliveryPayments = {
  id: "4",
  attributes: {
    title: "Доставка и оплата",
    slug: "dostavka-i-oplata",
    description: "Доставка и оплата",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
<center><img src="https://s3.madnuts.ru/img/icons2/10.png" alt="Миссия MadNuts"/></center>

# <center>Доставка и оплата</center>

## <center><span style="color:#7fad26">Доставим заказ в удобное время домой или в офис</style></center>
`,
      },
      {
        id: "2",
        __typename: "ComponentSharedDecoratedList",
        items: [
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons2/11.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Сердце выложенное из орехов",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
## Доставка по Санкт-Петербургу:
- от 4000 рублей: бесплатно в пределах КАД
- до 4000 рублей: 300 рублей в пределах КАД
- Доставка в другие районы СПБ и Ленобласти: от 350 рублей`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons2/12.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Сердце выложенное из орехов",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
## Доставка по РФ:
- Минимальный заказ для доставки - 4000 рублей
- Осуществляется транспортной компанией СДЭК, BoxBerry или Почтой России. Расчет стоимости уточняйте по телефону или WhatsApp, Telegram или В Контакте`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons2/13.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Сердце выложенное из орехов",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `## Сроки доставки:
- В удобное для вас время в течение 1-2 дней`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons2/14.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Сердце выложенное из орехов",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
## Оплата:
- Наличными при получении
- Банковской картой курьеру при получении
- На сайте через форму оплаты
- По ссылке NoMoney
- Доставка по РФ осуществляется по предоплате.`,
            },
          },
        ],
      },
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
#
<center><span style="color:#7fad26">Ваши орешки приедут к вам, упакованные в фирменный картонный пакет с замком Zip-Lock. Пакет герметичный, прочный и красивый. Его удобно взять с собой на прогулку, домой или на работу и перекусить в любом месте!</span></center>

# 
`,
      },
    ],
  },
};

const promocodeRegistration = {
  id: "5",
  attributes: {
    title: "Регистрация с промокодом",
    slug: "registraciya-s-promokodom",
    description: "Регистрация с промокодом",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# Магазин скидок: регистрация с промокодом

Если у вас есть промокод, то после регистрации вам будут доступны 5 видов скидок:`,
      },
      {
        id: "2",
        __typename: "ComponentSharedDecoratedList",
        items: [
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/elements/1.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Покупка на ладони",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
## Покупаю и продвигаю (ПиП)

Оптово-накопительная скидка, может достигать 80%!!! При регистрации равна 0%, но начинает автоматически быстро расти при совершении покупок самим участником и приглашенным им по его промокоду новым участникам. Если все эти участники совершают покупки регулярно, то скидка поддерживается на одном уровне или растёт. [Как это работает?](#)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/elements/2.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Игральные кости",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
## Удачливая

Эта скидка в 10% автоматически начисляется на два месяца вступающему в Клуб и участнику, его пригласившему, при совпадении суммы цифр их Промокодов, получаемых при регистрации. [Зарегистрироваться](#)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/elements/4.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Изображение человеческого мозга",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
## Интеллектуальная

Присваивается на два месяца вступающему в Клуб и участнику, его пригласившему (предоставившему свой Промокод), если им совместно удаётся из букв их промокодов сложить слово. [Как это работает?](#)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/elements/5.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Изображение открытой двери",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
## Вступительная

Эта скидка в 10% автоматически начисляется на два месяца всем, кто зарегистрировался в Клубе любителей орехов и сухофруктов "По карману". Действует сразу с момента регистрации. [Зарегистрироваться](#)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/elements/6.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Изображение купона",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
## Купонная

Эта скидка на два месяца в 10-30% автоматически разыгрывается случайным образом при регистрации. [Зарегистрироваться](#)`,
            },
          },
        ],
      },
    ],
  },
};

const vaucherRegistration = {
  id: "6",
  attributes: {
    title: "Регистрация с ваучером",
    slug: "registraciya-s-vaucherom",
    description: "Регистрация с ваучером",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# Магазин скидок: регистрация с ваучером

Если у вас есть нет промокода, а вы хотите получить скидки, то можете купить вступительный ваучер всего за 100 рублей. В таком случае после регистрации вам будут доступны 2 вида скидок.`,
      },
      {
        id: "2",
        __typename: "ComponentSharedDecoratedList",
        items: [
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/elements/5.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Изображение открытой двери",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
## Вступительная

Эта скидка в 10% автоматически начисляется на два месяца всем, кто зарегистрировался в Клубе любителей орехов и сухофруктов "По карману". Действует сразу с момента регистрации. [Зарегистрироваться](#)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/elements/6.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Изображение купона",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
## Купонная

Эта скидка на два месяца в 10-30% автоматически разыгрывается случайным образом при регистрации. [Зарегистрироваться](#)`,
            },
          },
        ],
      },
    ],
  },
};

const skidkaPiP = {
  id: "7",
  attributes: {
    title: "Скидка ПиП",
    slug: "skidka-pip",
    description: "Скидка ПиП",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# <center>Скидка <span style="color:#7fad26">Покупаю и продвигаю (ПиП)</span></center>

> Это уникальная индивидуальная оптово-накопительная скидочная система, стимулирующую покупательную и «профдвигательную» активность участников Клуба.`,
      },
      {
        id: "2",
        __typename: "ComponentSharedDecoratedList",
        items: [
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/8.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Сердце выложенное из орехов",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
**Зарегистрируйтесь.** Не забудьте использовать свой код приглашения (промокод, полученный у уже вступившего в Клуб участника). [Инструкция по регистрации](#)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/9.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Сердце выложенное из орехов",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
**После регистрации** вы получите личный промокод, который сможете передавать вашим друзьям и знакомым, которые тоже станут участниками клуба.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "3",
            image: {
              id: "3",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/10.png",
                    hash: "",
                    mime: "image/png",
                    name: "3",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Сердце выложенное из орехов",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
Те участники, которых вы пригласите в клуб, станут вашими рефералами, и смогут также приглашать своих рефералов в клуб, а те своих и так далее. Все они будут увеличивать размер вашей скидки.
- **Действует схема:**
  - Приглашаете больше людей, давая им свой промокод;
  - Они покупают товары в нашем магазине;
  - Чем больше они покупают, тем больше растет ваша скидка!`,
            },
          },
        ],
      },
      {
        id: "2",
        __typename: "ComponentSharedRichText",
        body: `
<center><img src="https://s3.madnuts.ru/img/elements/8.png" alt="Скидка ПиП может расти до 80%"/></center>

<center><span style="color:#7fad26">Подробное описание</color></center>
`,
      },
      {
        id: "3",
        __typename: "ComponentSharedRichText",
        body: `
<center><img src="https://s3.madnuts.ru/img/elements/7.png" alt="Скидка ПиП может расти до 80%"/></center>

# <center>Скидка <span style="color:#7fad26">Покупаю и продвигаю (ПиП)</span></center>

Как только вы зарегистрируетесь и получите свой личный промокод, вы можете привлекать новых участников клуба, тем самым увеличивая свою скидку.`,
      },
      {
        id: "2",
        __typename: "ComponentSharedDecoratedList",
        items: [
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "1",
            image: {
              id: "1",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons2/9.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Сердце выложенное из орехов",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
Все участники, которых вы привлекли, становятся вашими рефералами. Причём они также могут передавать свои промокоды, тем самым образуя целую реферальную сеть.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons2/9.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Сердце выложенное из орехов",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
Все рефералы влияют на размер вашей скидки. Чем больше количество людей вы привлечёте, просто подарив им свой промокод, тем больше могут вырасти ваши скидки!`,
            },
          },
        ],
      },
      {
        id: "2",
        __typename: "ComponentSharedRichText",
        body: `
> **Что для этого нужно делать? Ничего особенного.**  
> Только дарить свой личный промокод.

Это очень просто сделать! Вы можете рассказать по друзьям и знакомым, разместить на своей странице в соцсетях шаблон письма, высланный вам на почту при регистрации. Промокод там уже будет указан автоматически. А ещё в письме просто и понятно объяснется, почему вступление в Клуб «По карману» выгодно и полезно всем. Участники вашей цепочки приглашений будут совершать покупки, а ваша скидка будет увеличиваться.

![**Где я могу увидеть моих рефералов и мои скидки?**](https://s3.madnuts.ru/img/images/1.png)
![Скриншот открытого меню пользователя](https://s3.madnuts.ru/img/images/2.png)
Вы сможете увидеть дисконтное меню (свои скидки на текущий момент), ваших рефералов (участников, которых вы привлекли) в личном кабинете.
`,
      },
    ],
  },
};

const discountConditions = {
  id: "8",
  attributes: {
    title: "Условия получения скидок",
    slug: "usloviya-polucheniya-skidok",
    description: "Условия получения скидок",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
<center><img src="https://s3.madnuts.ru/img/icons/7.png" alt="Знак процента на купоне"/></center>

# Условия начисления скидок`,
      },
      {
        id: "2",
        __typename: "ComponentSharedDecoratedList",
        items: [
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/8.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Число 1",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: ` 
**Скидки в меню растут** при увеличении суммарной стоимости активных покупок, совершенных вами и всеми вашими рефералами. Активность покупок сохраняется в течении 2 месяцев (текущий накопительный период) со дня совершения покупки.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/9.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Число 2",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
Неактивные покупки на размер вашей скидки не влияют.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/10.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    provider: "me",
                    alternativeText: "Число 3",
                  },
                },
              },
            },
            primaryText: {
              id: "1",
              __typename: "ComponentSharedRichText",
              body: `
Суммарная дисконтированная стоимость покупок за текущий накопительный период не может превышать 15 000 руб. Если вы приобретаете продукцию на большую сумму, то превышение стоимости дисконтированно не подлежит.`,
            },
          },
        ],
      },
      {
        id: "2",
        __typename: "ComponentSharedRichText",
        body: `
<center><img src="https://s3.madnuts.ru/img/images/3.png" alt="**Когда моя скидка будет равна нулю?**"/></center>

> Если ваши рефералы (участники Клуба, привлеченные напрямую или по цепочке приглашений), не совершают за текущий накопительный период ни одной покупки. Такой же результат будет, если вы не привлекли ни одного участника.

![Attention Icon](path-to-attention-icon.png)

> **Это единственный вид скидок, который Клуб «По карману» готов у вас выкупить!**  
> Тем самым мы предлагаем вам возможность регулярного стабильного заработка.

![Две лужицы](https://s3.madnuts.ru/img/elements/9.png)

> **Все ваши скидки суммируются, но суммарная скидка не может быть больше 80%.**`,
      },
    ],
  },
};

const discountIntellectual = {
  id: "9",
  attributes: {
    title: "Интеллектуальная скидка",
    slug: "intellektualnaya-skidka",
    description: "Интеллектуальная скидка",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# Скидка <span style="color:#7fad26">Интеллектуальная</span>

- Присваивается на два месяца вступающему в Клуб и участнику, его пригласившему (давшему свой промокод), если им совместно удаётся из букв их промокодов сложить слово (существительное, нарицательное, литературное).
- Активируется в течение трех суток после заполнения специальной формы в разделе «Получить интеллектуальную скидку» в личном кабинете.
- В зависимости от числа букв в составленном слове предоставляются следующие скидки:

<center><img src="https://s3.madnuts.ru/img/tables/1.png" alt="Таблица скидок"/></center>

Пример составления слова и начисления скидок
`,
      },
    ],
  },
};

const registrationInstructions = {
  id: "10",
  attributes: {
    title: "Инструкция по регистрации",
    slug: "instrukciya-po-registracii",
    description: "Инструкция по регистрации",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# <center><span style=\"color:#7fad26\">Как зарегистрироваться?</span></center>
## <center>Инструкция</center>`,
      },
      {
        id: "2",
        __typename: "ComponentSharedDecoratedList",
        items: [
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/8.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
На главной странице нашего сайта в правом верхнем углу нажмите кнопку «Вход и регистрация».

![Кнопка входа и регистрации](https://s3.madnuts.ru/img/images/4.png)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/9.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
В открывающемся поля введите адрес электронной почты (по которому будет в дальнейшем проводиться ваша идентификация), номер телефона и код приглашения (промокод пригласившего вас участника Клуба).

![Форма входа](https://s3.madnuts.ru/img/images/6.png) ![Форма регистрации](https://s3.madnuts.ru/img/images/5.png)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/10.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
Если промокода у вас нет, нажмите на вход в личный кабинет, и в меню выберите раздел Ваши скидки.

![Показано как открыть раздел скидок](https://s3.madnuts.ru/img/images/7.png)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/11.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
Вы можете зарегистрироваться в Клубе, купив ваучер. Для этого нужно перейти по ссылке в открывшемся поле, положить ваучер в корзину и оплатить его.

![](https://s3.madnuts.ru/img/images/8.png)

После оплаты вам будут доступны скидки ![Discounts Available](path-to-discounts-available.png)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/12.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
Сразу после регистрации в вашем личном кабинете в разделе данные и настройки появится ваш личный промокод ![Personal Promo Code](path-to-personal-promo-code.png), который вы можете использовать для приглашения вами новых участников в Клуб.`,
            },
          },
        ],
      },
    ],
  },
};

const promocode = {
  id: "11",
  attributes: {
    title: "Промокод",
    slug: "promokod",
    description: "Промокод",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# Промокод и как его использовать

**Промокод** участника Клуба «По карману» содержит 19 позиций, заполненных цифрами или буквами. Выглядит **промокод** следующим образом:

![Схема промокода: Обозначение реферальной скидки - Случайным образом трансформируемые символы - Дата регистрации члена Клуба «По карману»](https://s3.madnuts.ru/img/tables/2.png)

Участник клуба может использовать Промокод для получения **разовых скидок** сразу после регистрации. Это скидки:
- [<span style="color:#7fad26">Удачливая</span>](/registraciya-s-promokodom)
- [<span style="color:#7fad26">Интеллектуальная</span>](/skidka-intellektualnaya)

а также для формирования накопительной скидки [<span style="color:#7fad26">Покупаю и продвигаю (ПиП)</span>](/skidka-pip)
`,
      },
    ],
  },
};

const discountCalculation = {
  id: "12",
  attributes: {
    title: "Расчет скидки",
    slug: "raschet-skidki",
    description: "Расчет скидки",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# Примеры начисления скидок`,
      },
      {
        id: "2",
        __typename: "ComponentSharedDecoratedList",
        items: [
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/8.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
Предположим, Участник А зарегистрировался с промокодом участника Б:

![Схема промокода](https://s3.madnuts.ru/img/tables/3.png)`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/9.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
После регистрации Участник А получил собственный промокод:

![Схема промокода](https://s3.madnuts.ru/img/tables/3.png)

После регистрации вы получите личный промокод, который сможете передавать вашим друзьям и знакомым, которые тоже смогут стать участниками клуба.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/10.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
Посмотрим, какие конкретные скидки и когда смогут получить участники А и Б:
- **Скидка «Вступительная»**.
  Эту скидку в 10% на два месяца сразу при регистрации получает участник А. Участник Б свою вступительную скидку уже получил, когда регистрировался сам.

- **Скидка «Удачливая»**.
  Сумма цифр промокода участника А составляет: 6+4+5+1+1+0+9+2+0+2+3 = 33,
  Сумма цифр промокода участника Б: 2+6+9+1+1+8+0+7+2+0+2+3 = 39.

Поскольку сумма цифр промокодов совпадает, у участник А, и участник Б получат на два месяца эту скидку в 10% от стоимости совершаемых покупок.
- **Скидка «Интеллектуальная»**.
  Промокоды участников содержат определенные наборы букв:

  - Промокод участника А: Р Е Ф Н В Ж П,
  - Промокод участника Б: Р Е Ф О М И Х С

  Из этих букв можно составить пятибуквенное слово: РЕЖИМ

  Таким образом, участник А получает на 2 месяца скидку «интеллектуальная» в 50%, а участник Б – 25%.`,
            },
          },
        ],
      },
    ],
  },
};

const whatDoWeSuggest = {
  id: "13",
  attributes: {
    title: "Что мы предлагаем?",
    slug: "chto-my-predlagaem",
    description: "Что мы предлагаем?",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# Что мы предлагаем?

Клуб «По карману» предлагает своим участникам сотрудничество!

Вы сможете привлекать в Клуб «По Карману» новых участников (рефералов), которым могут стать ваши родственники, друзья, знакомые. Это позволит вам не только покупать мерчандайз на всю нашу продукцию неограниченное время, но и зарабатывать реальные деньги!

## Как привлечь рефералов

- Самый простой способ привлека рефералов - разослать вашим родственникам, друзьям и знакомым рекомендательное письмо. За основу можно взять шаблон, направленный вам на электронную почту вместе с поздравлением со вступлением в Клуб.
- Можно также разместить это письмо на вашей странице в соцсетях, на вашем сайте или сайте ваших друзей или любым другим способом.
- Ваше старое письмо заглядывайте в свой Личный кабинет. Как только кто-то из ваших рефералов (новопривлеченных рефералов или рефералов ваших рефералов до любого уровня глубины) совершит покупку, вы узнаете об этом, уведомление появление в разделе «Ваш скидки» Личного Кабинета много.
- Кроме того, информация о покупках ваших рефералов будет отображаться в разделе Личного кабинета «Ваши рефералы».

## Что влияет на скорость роста скидок

Скидки с дисконтом меню будут расти вместе с ростом числа и стоимостью покупок ваших рефералов. Еще быстрее их рост будет при росте числа и стоимости покупок, если вы тоже начнете совершать покупки. Эти скидки мы называем персональными, они распространяются на всю продукцию MADNUTS и могут достигать 80%.`,
      },
    ],
  },
};

const howToSellDiscounts = {
  id: "14",
  attributes: {
    title: "Как продавать скидки?",
    slug: "kak-prodavat-skidki",
    description: "Как продавать скидки?",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# Как продать скидку

Если Вы не хотите приобретать орехи, сухофрукты, специи и прочие товары из ассортимента MADNUTS, но вам хочется сотрудничать с нами, вы всегда можете продать Ваш реферальные скидки (точнее, накопленный вами Дисконтный опцион). На данный момент мы готовы оплачивать дисконтные опционы, исходя из соотношения:

1% максимальной скидки ДМ = 250 руб

Таким образом, если вы хорошо поработали и вышли на максимальную реферальную скидку в 80%, то мы готовы ее выкупить у вас за 20 000 руб.

Для продажи нам вашего дисконтного опциона просим связаться с нами одним из способов, указанных на сайте.

> Так как идентификатором участника Клуба выступает адрес его электронной почты, то Вы сможете регистрироваться в клубе многократно с различными адресами и на каждый из них получать реферальные скидки. Таким образом, величина вашего заработка будет зависеть только от вас!`,
      },
    ],
  },
};

const faq = {
  id: "15",
  attributes: {
    title: "Часто задаваемые вопросы",
    slug: "chasto-zadavaemye-voprosy",
    description: "Часто задаваемые вопросы",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# Вопросы и ответы`,
      },
      {
        id: "2",
        __typename: "ComponentSharedDecoratedList",
        items: [
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/8.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**Вы декларируете, что предоставляете скидки до 80%. Как такое возможно, если каталожные цены у вас на уровне рыночных, а известно, что наценка в рознице на вашу продукцию 30-40%.**

Давайте разберемся. Скидки, которые можно получить без особого труда, практически по факту регистрации в Клубе «По карману» (Вступительная, Удачливая, Интеллектуальная, Купонная) — это скидки категории «Мгновенные». Срок их действия — 2 месяца с момента регистрации либо (для скидки «Интеллектуальная») с момента заполнения соответствующей формы.

В этом случае наши временные убытки при продажах с большими скидками равнозначны маркетинговым расходам при «заглатывании» покупателей, они окупаются при последующих покупках, что обычная практика розничных магазинов.

Иначе обстоит дело со скидкой «Покупаю и продаваю». Это скидка долговременная при условии, что ваши рефералы сами совершают покупки и привлекают новых покупателей. Алгоритм расчета скидки «ПиП» настроен так, что средняя скидка по всем участникам клуба поддерживается ниже скидки, предоставляемых нам оптовыми поставщиками. Т.е., если нарастить цепочку привлечения участников получает скидку до 80%, то новые участники цепочки на нижних уровнях получают скидку 0-10%. За счет этого долговременные скидки позволяют и нам, и участникам Клуба зарабатывать.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/9.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**Если за текущий накопительный период я буду покупать на большую сумму, а привлеченные мною участники вообще не будут покупать — я не получу скидку «ПиП»?**

В этом случае вы, увы, скидку не получите. Система так построена, что скидки появляются только когда и вы, и привлеченные вами (хотя бы кто-то из них) совершают регулярные покупки в нашем интернет-магазине.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/10.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**Почему, если я покупаю товары на сумму больше 15 тыс. рублей, скидка «ПиП» не действует?**

Как и в любом бизнесе, наш бизнес имеет тоже имеет свои ограничения. Это такой объем продаж, до достижения которого бизнес убыточен. Это нормально, но для снижения возможных убытков на начальном этапе развертывания бизнеса, мы решили ограничить объем продаж среднестатистическим семейным потребностям в нашей продукции. С ростом числа участников это ограничение, скорее всего, будет снято.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/11.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**Получается, что покупки надо совершать регулярно, иначе скидки с течением времени «сгорают»?**

Да, именно так. Первые два месяца с момента регистрации у вас действуют кратковременные скидки. Ваш сразу, практически без усилий, сможете получить скидку от 10%-80%. Через два месяца эти скидки истекут, но за это время вы сможете привлечь своих рефералов (покупателей, которые зарегистрировались с вашим промокодом) и получить уже долговременную скидку «ПиП». Для поддержания этой скидки максимально высокой (она может достигать 80%) надо и самому регулярно совершать покупки, и призывать к этому своих рефералов, стараясь максимально увеличить их число.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/12.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**А других видов скидок в вашем магазине уже не будет? Только такие, как есть сейчас?**

Возможно, будут. Наши покупатели очень разные. Кого-то устраивают и каталожные цены на продукцию. Кто-то хочет быть полностью независимым и не пропагандирует каких-либо усложнений для этого не вовсе. Кому-то интересно «полигать» с буквами промокодов и получать внушающие наградежение своих интеллектуальных способностей. А кому-то интересно покупать орехи и сухофрукты собственно, скажем за 20% от рыночной стоимости, и еще на этом зарабатывать. Мы готовы всех и готовы работать со всеми. Мы работаем над новыми видами скидок.`,
            },
          },
        ],
      },
    ],
  },
};

const advantagesOfMadnuts = {
  id: "16",
  attributes: {
    title: "Преимущества Madnuts",
    slug: "preimushchestva-madnuts",
    description: "Преимущества Madnuts",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# Преимущества MadNuts`,
      },
      {
        id: "2",
        __typename: "ComponentSharedDecoratedList",
        items: [
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/16.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**Широкий ассортимент**
У нас действительно широкий ассортимент. Если вы не обнаружили в каталоге то, что вам нужно – просто напишите нам и мы постараемся найти этот продукт.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/18.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**Соотношение цена/качество**
У нас один из самых выгодных цен в Санкт-Петербурге на орехи, сухофрукты и другую продукцию самого высокого качества. Мы дополнительно проверяем все товары перед отправкой.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/17.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**Уникальные скидки**
В нашем интернет-магазине вы сможете приобрести весь ассортимент продукции с различными скидками, которые не найдёте у конкурентов. Все виды скидок суммируются и эта сумма может доходить до 80%.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/19.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**Оперативность**
Мы работаем, чтобы быть ближе к вам. У нас оперативная доставка в удобное для вас время и место.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/20.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**Обратная связь**
Мы всегда проконсультируем по любым вопросам, поможем с выбором продукции. Для вас всегда есть постоянные акции, распродажи и приятные сюрпризы.`,
            },
          },
          {
            __typename: "ComponentSharedDecoratedListItem",
            id: "2",
            image: {
              id: "2",
              __typename: "ComponentSharedMedia",
              file: {
                data: {
                  attributes: {
                    url: "https://s3.madnuts.ru/img/icons/21.png",
                    hash: "",
                    mime: "image/png",
                    name: "2",
                    size: 42,
                    width: 42,
                    alternativeText: "Сердце выложенное из орехов",
                    provider: "me",
                  },
                },
              },
            },
            primaryText: {
              id: "2",
              __typename: "ComponentSharedRichText",
              body: `
**Совместный бизнес**
Для желающих мы всегда открыты к совместному бизнесу и готовы обеспечить вам высокий и стабильный заработок в интернете без вложений.`,
            },
          },
        ],
      },
    ],
  },
};

const poKarmanu = {
  id: "17",
  attributes: {
    title: "По карману",
    slug: "po-karmanu",
    description: "По карману",
    blocks: [
      {
        id: "1",
        __typename: "ComponentSharedRichText",
        body: `
# Скидки: клуб любителей орехов и сухофруктов "По карману"

## Зачем нужна регистрация?

Купить товары в нашем магазине можно и без регистрации. Регистрация делает покупателя участником клуба «По карману» и даёт доступ к разным видам скидок.

Наши скидки могут дать вам экономию при покупке на нашем сайте **от 10 до 80%**

## Как зарегистрироваться

Для регистрации есть 2 способа:

- **Промокод**
  Если у вас есть промокод, который вы получили у ваших друзей и знакомых членов клуба"По карману":
  Какие скидки я могу получить?

- **Ваучер**
  Если у вас нет промокода, для регистрации вы можете купить ваучер стоимостью 100 руб.
  Какие скидки я могу получить?

[Перейти к регистрации](#registration-link)`,
      },
    ],
  },
};

const articles = [
  ...news,
  pochemuMadnuts,
  mission,
  deliveryPayments,
  promocodeRegistration,
  vaucherRegistration,
  skidkaPiP,
  discountConditions,
  discountIntellectual,
  registrationInstructions,
  promocode,
  discountCalculation,
  whatDoWeSuggest,
  howToSellDiscounts,
  faq,
  advantagesOfMadnuts,
  poKarmanu,
];

module.exports = {
  articles,
  news,
  pochemuMadnuts,
  mission,
  deliveryPayments,
  promocodeRegistration,
  vaucherRegistration,
  skidkaPiP,
  discountConditions,
  discountIntellectual,
  registrationInstructions,
  promocode,
  discountCalculation,
  whatDoWeSuggest,
  howToSellDiscounts,
  faq,
  advantagesOfMadnuts,
  poKarmanu,
};
