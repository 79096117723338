import {
  AppRegistrationOutlined,
  CallOutlined,
  Code,
  DiscountOutlined,
  ExpandLess,
  ExpandMore,
  Flag,
  GroupsOutlined,
  HandshakeOutlined,
  HowToReg,
  InfoOutlined,
  Inventory2Outlined as Inventory2OutlinedIcon,
  LocalOfferOutlined,
  LockOpenOutlined,
  Menu,
  NewspaperOutlined,
  NumbersOutlined,
  QuestionAnswerOutlined,
  SellOutlined,
  ShoppingCartCheckoutOutlined,
  ShoppingCartOutlined,
  StarBorder,
  StarBorderPurple500Outlined,
  StorefrontOutlined,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  AppBarProps,
  Box,
  Button,
  Collapse,
  Container,
  Hidden,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { usePreloadedQuery } from "react-relay";
import { useAuth, useConfig, useNavigate } from "../core";
import {
  useLocation,
  useURLSearchParams,
  useUrlSetQueryParam,
} from "../core/history";
import { CatalogMenu } from "../menus";
import NavigationDrawer from "../menus/NavigationDrawer";
import { UserMenuButton } from "../menus/UserMenuButton";
import AppContextProviderQuery from "../queries/AppContextProviderQuery.graphql";
import { useAppContext } from "./AppContextProvider";
import { AppLogo } from "./AppLogo";
import CartButton from "./CartButton";
import { ContactsBox } from "./ContactsBox";
import { DropdownMenuButton } from "./DropdownMenuButton";
import { ElevationScroll } from "./ElevationScroll";
import { MenuItemDescr } from "./MenuItemDescr";
import MyBadge from "./MyBadge";
import { SignInButton } from "./SignInButton";

type AppToolbarProps = Omit<AppBarProps, "children">;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: 0,
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  [theme.breakpoints.up("xs")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "13ch",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20ch",
    },
  },
}));

const menuButtons: MenuItemDescr[] = [
  {
    id: "zakazy",
    icon: <Inventory2OutlinedIcon />,
    text: "Мои заказы",
    href: "/orders",
    drawerOnly: true,
  },
  {
    id: "chem-my-luchshe",
    icon: <Flag />,
    text: "Чем мы лучше?",
    items: [
      {
        id: "pochemu-stoit-vibirat-madnuts",
        icon: <StarBorder />,
        href: "/pochemu-madnuts",
        text: "Почему стоит выбирать MadNuts",
      },
      {
        id: "nasha-missiya",
        icon: <Flag />,
        href: "/missiya",
        text: "Наша миссия",
      },
      {
        id: "orehi-dlya-vseh",
        icon: <StarBorder />,
        href: "/missiya",
        text: "Как сделать орехи и сухофрукты доступными для людей?",
      },
      {
        id: "preimushchestva-madnuts",
        icon: <StarBorder />,
        href: "/preimushchestva-madnuts",
        text: "Преимущества MadNuts",
      },
      {
        id: "dostavka-i-oplata",
        icon: <StarBorder />,
        href: "/dostavka-i-oplata",
        text: "Доставка и оплата",
      },
    ],
  },
  {
    id: "klub-po-karmanu",
    icon: <GroupsOutlined />,
    text: "Скидки: Клуб «По карману»",
    items: [
      {
        id: "registraciya",
        icon: <AppRegistrationOutlined />,
        href: "/po-karmanu",
        text: "Зачем нужна регистрация? ",
      },
      {
        id: "kak-zaregistrirovatsya",
        icon: <AppRegistrationOutlined />,
        href: "/kak-zaregistrirovatsya",
        text: "Как зарегистрироваться? (Инструкция)",
      },
      {
        id: "registraciya-s-promokodom",
        icon: <AppRegistrationOutlined />,
        href: "/registraciya-s-promokodom",
        text: "Магазин скидок: Регистрация с промокодом ",
      },
      {
        id: "registraciya-po-vaucheru",
        icon: <AppRegistrationOutlined />,
        href: "/registraciya-po-vaucheru",
        text: "Магазин скидок: Регистрация c ваучером",
      },
      {
        id: "promokod",
        icon: <AppRegistrationOutlined />,
        href: "/promokod",
        text: "Промокод и как его использовать ",
      },
      {
        id: "skidka-intellektualnaya",
        icon: <DiscountOutlined />,
        href: "/skidka-intellektualnaya",
        text: "Скидка «Интеллектуальная»",
      },
      {
        id: "skidka-pip",
        icon: <DiscountOutlined />,
        href: "/skidka-pip",
        text: "Скидка «ПиП»",
      },
      {
        id: "primery-nachisleniya-skidok",
        icon: <DiscountOutlined />,
        href: "/primery-nachisleniya-skidok",
        text: "Примеры начисления скидок ",
      },
    ],
  },
  {
    id: "partnerstvo",
    icon: <HandshakeOutlined />,
    text: "Партнёрство",
    items: [
      {
        id: "chto-my-predlagaem",
        icon: <LocalOfferOutlined />,
        href: "/chto-my-predlagaem",
        text: "Что мы предлагаем?",
      },
      {
        id: "kak-prodat-skidku",
        icon: <SellOutlined />,
        href: "/kak-prodat-skidku",
        text: "Как продать скидку?",
      },
    ],
  },
  {
    id: "voprosy-i-otvety",
    icon: <QuestionAnswerOutlined />,
    text: "Вопросы и ответы",
    href: "/voprosy-i-otvety",
  },
  {
    id: "novosti",
    icon: <NewspaperOutlined />,
    href: "/novosti",
    text: "Новости",
  },
  {
    id: "kontakty",
    icon: <CallOutlined />,
    href: "/#kontakty",
    text: "Контакты",
  },
];

// const menuButtons: MenuItemDescr[] = [
//   {
//     id: "zakazy",
//     icon: <Inventory2OutlinedIcon />,
//     text: "Мои заказы",
//     href: "/orders",
//     drawerOnly: true,
//   },
//   {
//     id: "klub-po-karmanu",
//     icon: <HandshakeOutlined />,
//     text: "Клуб «По карману»",
//     href: "/klub-po-karmanu",
//   },
//   {
//     id: "registraziya-i-oformlenie",
//     icon: <HowToReg />,
//     text: "Регистрация и оформление заказа",
//     items: [
//       {
//         id: "kak-zakazat",
//         icon: <StorefrontOutlined />,
//         href: "/#kak-zakazat",
//         text: "Как заказать",
//       },
//       {
//         id: "kak-zaregistrirovatsya",
//         icon: <HowToReg />,
//         href: "/#kak-zaregistrirovatsya",
//         text: "Как зарегистрироваться",
//       },
//       {
//         id: "kod-priglasheniya",
//         icon: <Code />,
//         href: "/#kod-priglasheniya",
//         text: "Где взять Код приглашения",
//       },
//     ],
//   },
//   {
//     id: "kak-poluchit-skidku",
//     icon: <Inventory2OutlinedIcon />,
//     href: "/#kak-poluchit-skidku",
//     text: "Хочу получить скидку",
//   },
//   {
//     id: "kak-prodat-skidku",
//     icon: <ShoppingCartOutlined />,
//     href: "/#kak-prodat-skidku",
//     text: "Хочу продать скидку",
//   },
//   {
//     id: "kak-zarabotat",
//     icon: <HandshakeOutlined />,
//     href: "/#kak-zarabotat",
//     text: "Работа онлайн с Клубом «По карману»",
//   },
//   {
//     id: "dostavka",
//     icon: <ShoppingCartCheckoutOutlined />,
//     href: "/#dostavka",
//     text: "Доставка и оплата",
//   },
//   {
//     id: "madnuts",
//     icon: <InfoOutlined />,
//     href: "/#madnuts",
//     text: "О MadNuts",
//   },
//   {
//     id: "novosti",
//     icon: <NewspaperOutlined />,
//     href: "/novosti",
//     text: "Новости",
//   },
// ];

interface VerticalMenuItemProps {
  descr: MenuItemDescr;
  sx?: SxProps<Theme>;
}

function VerticalMenuItem(props: VerticalMenuItemProps) {
  const { id, href, icon, text, items } = props.descr;
  const [open, setOpen] = React.useState(true);
  const hasSubitems = !!items && items.length !== 0;
  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        sx={props.sx}
        key={id}
        href={href ?? ""}
        onClick={hasSubitems ? onClick : undefined}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {hasSubitems && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {hasSubitems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map((i) => (
              <VerticalMenuItem key={i.id} descr={i} sx={{ pl: 4 }} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

function AppToolbar(props: AppToolbarProps): JSX.Element {
  const catalogMenuAnchorRef = React.createRef<HTMLAnchorElement>();
  const { me } = useAuth();
  const navigate = useNavigate();

  const appContext = useAppContext();
  const appData = usePreloadedQuery(
    AppContextProviderQuery,
    appContext.queryRef
  );

  const urlSearchParams = useURLSearchParams();
  const searchParam = urlSearchParams.get("search") ?? "";
  const urlLocation = useLocation();

  const config = useConfig();

  const setSearch = useUrlSetQueryParam("search", config.app.catalogRoot);
  const search = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(
        event.target.value,
        urlLocation.pathname === config.app.catalogRoot
      );
    },
    [setSearch]
  );

  const [anchorEl, setAnchorEl] = React.useState({
    userMenu: null as HTMLElement | null,
    notifications: null as HTMLElement | null,
    catalogMenu: null as HTMLElement | null,
  });

  function openCatalogMenu() {
    setAnchorEl((x) => ({ ...x, catalogMenu: catalogMenuAnchorRef.current }));
  }

  function closeCatalogMenu() {
    setAnchorEl((x) => ({ ...x, catalogMenu: null }));
  }

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const onCloseDrawer = React.useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const height = smDown ? "4.2em" : mdDown ? "3.5em" : "7.15em";
  const ordersCount = appData.orders?.totalCount;

  return (
    <>
      <Hidden mdUp>
        <NavigationDrawer open={drawerOpen} onClose={onCloseDrawer}>
          <Box
            sx={{ width: 330 }}
            role="presentation"
            onClick={onCloseDrawer}
            onKeyDown={onCloseDrawer}
          >
            <AppLogo sx={{ m: 2 }} />
            <List>
              {menuButtons.map((descr) => (
                <VerticalMenuItem key={descr.id} descr={descr} />
              ))}
            </List>
          </Box>
        </NavigationDrawer>
      </Hidden>
      <ElevationScroll>
        <AppBar {...props} sx={{ height }}>
          <Container>
            <Toolbar disableGutters>
              <Hidden mdDown>
                <AppLogo />
              </Hidden>
              <Hidden mdUp>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setDrawerOpen(true)}
                >
                  <Menu />
                </IconButton>
              </Hidden>
              <Button
                sx={{ ml: { xs: 0, sm: 2 } }}
                href=""
                variant="contained"
                onClick={openCatalogMenu}
                ref={catalogMenuAnchorRef}
                color="secondary"
              >
                Каталог
              </Button>

              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Искать…"
                  inputProps={{ "aria-label": "search" }}
                  onChange={search}
                  value={searchParam}
                />
              </Search>

              <span style={{ flexGrow: 1 }} />

              <Hidden mdDown>
                <ContactsBox marginRight={3} />
              </Hidden>

              <CartButton />

              <Hidden smDown>
                <MyBadge badgeContent={ordersCount} color="primary">
                  <Tooltip
                    title="Откройте список своих заказов"
                    enterDelay={800}
                  >
                    <IconButton
                      href="/orders"
                      onClick={navigate}
                      color="inherit"
                    >
                      <Inventory2OutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </MyBadge>
              </Hidden>

              {me && <UserMenuButton />}
              {!me && (
                <Tooltip title="Войдите или зарегистрируйтесь" enterDelay={800}>
                  <SignInButton
                    sx={{ ml: 2 }}
                    variant="outlined"
                    color="inherit"
                  >
                    {smDown ? "Войти" : "Вход/Регистрация"}
                  </SignInButton>
                </Tooltip>
              )}
            </Toolbar>
            <Hidden mdDown>
              <Toolbar disableGutters>
                {menuButtons
                  .filter((b) => !b.drawerOnly)
                  .map((b) => (
                    <DropdownMenuButton
                      key={b.id}
                      text={b.text}
                      color={b.color}
                      href={b.href}
                      items={b.items}
                    />
                  ))}
              </Toolbar>
            </Hidden>
          </Container>

          {/* Pop-up menus */}

          <CatalogMenu
            gridMode
            anchorEl={anchorEl.catalogMenu}
            onClose={closeCatalogMenu}
          />
        </AppBar>
      </ElevationScroll>
      <Box sx={{ height: height }} />
    </>
  );
}

export { AppToolbar };
